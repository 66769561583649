import React from 'react';
//import { JsonData } from './stockChart'; // Adjust the import path as needed

interface JsonData {
    Date: string;
    Open: string;
    High: string;
    Low: string;
    Close: string;
    Prediction: string;
    Accuracy: string;
    companyName?: string;
    livePrice?: string;
    priceChange?: string;
    percentageChange?: string;
    exchange?: string;
}

interface PredictionTableProps {
  data: JsonData[];

}


export const PredictionTable: React.FC<PredictionTableProps> = ({data}) => {
    
    const getFilteredData = (): JsonData[] => {
        const today = new Date(new Date().toLocaleString('en-US', { timeZone: 'America/New_York' }));
        const todayDateStr = today.toISOString().split('T')[0];
     //   console.log(`Today's date: ${todayDateStr}`);

        // Convert the dates in data to the same format
        const dataDates = data.map(d => d.Date);

        // Find the closest past date
        let pastIndex = -1;
        for (let i = dataDates.length - 1; i >= 0; i--) {
            if (dataDates[i] <= todayDateStr) {
                pastIndex = i;
                break;
            }
        }

        // Find the closest future date
        let futureIndex = -1;
        for (let i = 0; i < dataDates.length; i++) {
            if (dataDates[i] >= todayDateStr) {
                futureIndex = i;
                break;
            }
        }

        // Find today's date
        const todayIndex = dataDates.indexOf(todayDateStr);

        // Define pastDates and futureDates with explicit types
        let pastDates: JsonData[] = [];
        let futureDates: JsonData[] = [];

        if (todayIndex !== -1) {
            pastDates = data.slice(Math.max(todayIndex - 2, 0), todayIndex);
            futureDates = data.slice(todayIndex + 1, todayIndex + 3);
        } else {
            if (pastIndex !== -1) pastDates = data.slice(Math.max(pastIndex - 1, 0), pastIndex + 1);
            if (futureIndex !== -1) futureDates = data.slice(futureIndex, futureIndex + 2);
        }

        // Combine past and future dates, ensuring no duplicates and sorting them
        const combinedDates = [...pastDates, ...(todayIndex !== -1 ? [data[todayIndex]] : []), ...futureDates];
        const uniqueDates = combinedDates.filter((v, i, a) => a.findIndex(t => t.Date === v.Date) === i).sort((a, b) => new Date(a.Date).getTime() - new Date(b.Date).getTime());

        return uniqueDates;
    };

    const formatDateForDisplay = (dateStr: string): string => {
        const date = new Date(dateStr + 'T00:00:00-05:00'); // Treat the date as EST
     //   console.log(`Date string is: ${dateStr}`);
        const options: Intl.DateTimeFormatOptions = {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
            timeZone: 'America/New_York' // Force EST to avoid local time zone conversion issues
        };
        return date.toLocaleDateString('en-US', options);
    };
  return (
    <div className="stockChart_prediction-table" key={`${new Date().getTime()}`}>
      <div className="stockChart_prediction-table_column">
      {getFilteredData().map((row, index) => {
                  //          console.log(row); // Debugging line to check data
                            let accuracy: string | null = null;

                            if (parseFloat(row.Close) === 0) {
                                accuracy = parseFloat(row.Prediction) === 0 ? '0' : null;
                            } else {
                                accuracy = (100 - Math.abs((parseFloat(row.Close) - parseFloat(row.Prediction)) / parseFloat(row.Close)) * 100).toFixed(2);
                            }

                            const accuracyClass = accuracy !== null
                                ? (accuracy == 'NaN'
                                    ? 'stockChart_prediction-table_cell'
                                    : (parseFloat(accuracy) > 97
                                        ? 'accuracy-green'
                                        : 'accuracy-red'))
                                : 'stockChart_prediction-table_cell';

                            return (
                                <div key={index} className={index === 2 ? "stockChart_prediction-table_subColumn-today" : "stockChart_prediction-table_subColumn"}>
                                    <div className="stockChart_prediction-table_cell-Header">{formatDateForDisplay(row.Date)}</div>
                                    <div className="stockChart_prediction-table_cell-prediction">{row.Prediction !== "" ? `$${row.Prediction}` : ''}</div>
                                    <div className="stockChart_prediction-table_cell-close">{row.Close !== "" ? `$${row.Close}` : ''}</div>
                                    <div className={`stockChart_prediction-table_cell-${accuracyClass}`}>
                                        {accuracy !== null && accuracy !== "NaN" ? <p>Accuracy</p> : ''}
                                        {accuracy !== null && accuracy !== "NaN" ? `${accuracy}%` : ''}
                                    </div>
                                </div>
                            );
                        })}
      </div>
    </div>
  );
};