import React from 'react';
import { User } from './userContext';

interface PricePlansProps {
  componentTitle: string[];
  freePlan: {
    title: string;
    details: string[];
  };
  proPlan: {
    title: string;
    details: string[];
  };
  plusPlan: {
    title: string;
    details: string[];
  };
  planBtn: string[];
  userDetails: User;
  labels: string[];
}

const PricePlans: React.FC<PricePlansProps> = ({ componentTitle, freePlan, proPlan, plusPlan, planBtn, userDetails, labels }) => {
  return (
    <div className="PricePlans-Container">
      {userDetails.premium_type === 'spm' ? (
        <h1 className="PricePlans-Container-title">{componentTitle[2]}</h1>
      ) : userDetails.premium_type === 'spm+' ? (
        <h1 className="PricePlans-Container-title">{componentTitle[3]}</h1>
      ) : userDetails.premium_type === 'free' ? (
        <h1 className='PricePlans-Container-title'>{componentTitle[1]}</h1>
      ): <h1 className='PricePlans-Container-title'>{componentTitle[0]}</h1>
      }

      <div className="PricePlans-Plan-preContainer">
        {/** FREE CONTAINER */}
        <div className="PricePlans_Plan-container">
          <h2 className='PricePlans_Plan-title-free'>{freePlan.title}</h2>
          <div className="PricePlans_Plan-subcontainer">
            {freePlan.details.map((detail, index) => (
              <p key={index}>{detail}</p>
            ))}
          </div>
          {userDetails.premium_type === 'spm' ? (
            <button className='PricePlans_Plan-downgradeBtn'>{planBtn[1]}</button>
          ) : userDetails.premium_type === 'spm+' ? (
            <button className='PricePlans_Plan-downgradeBtn'>{planBtn[1]}</button>
          ) : userDetails.premium_type === 'free' ? (
            <button className='PricePlans_Plan-selectedBtn'>{planBtn[2]}</button>
          ): <a href="/signUp" className='PricePlans_Plan-upgradeBtn'>{planBtn[0]}</a>
          }
        </div>
        <div className="PricePlans_Plan-container">
            {/** PRO CONTAINER */}
          <h2 className='PricePlans_Plan-title-pro'>{proPlan.title}</h2>
          <div className="PricePlans_Plan-subcontainer">
          <div className="PricePlans_Plan-subcontainer-blur">
            {proPlan.details.map((detail, index) => (
              <p key={index}>{detail}</p>
            ))}
            </div>
          </div>
          {userDetails.premium_type === 'spm' ? (
            <button className='PricePlans_Plan-selectedBtn'>{planBtn[2]}</button>
          ) : userDetails.premium_type === 'spm+' ? (
            <button className='PricePlans_Plan-downgradeBtn'>{planBtn[1]}</button>
          ) : userDetails.premium_type === 'free' ? (
            <button className='PricePlans_Plan-upgradeBtn'>{planBtn[3]}</button>
          ): <a onClick={() => alert("This plan is not available yet!")} className='PricePlans_Plan-upgradeBtn'>{labels[0]}</a>
          }
        </div>
        <div className="PricePlans_Plan-container">
            {/** PLUS CONTAINER */}
          <h2 className='PricePlans_Plan-title-plus'>{plusPlan.title}</h2>
          
          <div className="PricePlans_Plan-subcontainer">
            {plusPlan.details.map((detail, index) => (
              <div key={index} className="PricePlans_Plan-subcontainer-blur">
                <p key={index}>{'Text Details not available'}</p>
              </div>
            ))}
          </div>
          {/*
          {userDetails.premium_type === 'spm' ? (
            <button className='PricePlans_Plan-upgradeBtn'>{planBtn[3]}</button>
          ) : userDetails.premium_type === 'spm+' ? (
            <button className='PricePlans_Plan-selectedBtn'>{planBtn[2]}</button>
          ) : userDetails.premium_type === 'free' ? (
            <button className='PricePlans_Plan-upgradeBtn'>{planBtn[3]}</button>
          ): <a onClick={() => alert("This Action is only available after Launch!")} className='PricePlans_Plan-upgradeBtn'>{planBtn[0]}</a>
          }
          */}
          {<button className='PricePlans_Plan-upgradeBtn' onClick={() => alert("This plan is not available yet!")}>{labels[0]}</button>}
        </div>
      </div>
    </div>
  );
};

export default PricePlans;
