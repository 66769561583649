import React, { useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useUser } from './userContext';

interface LoginProps {
  title: string;
  subtitle: string;
  subtitleAlt: string;
  placeholder: string;
  loginBtn: string;
  signIn: string;
  signUp: string;
  spmUrl: string;
  mode: string;
  alerts: string[];
}

const Login: React.FC<LoginProps> = ({ title, subtitle, subtitleAlt, placeholder, loginBtn, signIn, signUp, spmUrl, mode, alerts }) => {
  const emailRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { setVerificationRequired } = useUser();

  const handleLoginClick = async () => {
    const email = emailRef.current?.value;
    if (!email) {
      alert('Please enter your email');
      return;
    }

    try {
      const response = await fetch(`${spmUrl}php/${mode}.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (data.message === 'Verification code sent.') {
        // Set verification required and navigate to verification page
        setVerificationRequired(true);
        navigate('/verification', { state: { from: location, email } }); // Pass current location and email as state
      } 
      else if (data.message === 'Error sending verification code.') {
        emailRef.current.value = '';
        emailRef.current.placeholder = alerts[4];
        emailRef.current.style.color = 'red';
        console.log(data);
      }
      else if (data.message === 'Registration Successful.') {
        alert(alerts[1]);
        navigate('/login', { state: { from: location } });
        console.log(data);
      }
      else if (data.message === 'User Exists.') {
        emailRef.current.value = '';
        emailRef.current.placeholder = alerts[2];
        emailRef.current.style.color = 'red';
        console.log(data);
      }
      else {
        emailRef.current.value = '';
        emailRef.current.placeholder = alerts[0];
        emailRef.current.style.color = 'red';
        console.log(data);
      }
    } catch (error) {
      alert(alerts[4]);
      console.log(error);
    }
  };

  return (
    <div className='Login_Component'>
      <div className='Login_Container'>
        <div className='Login_Form'>
          <a href="/" className='Login_logo'></a>
          <h1 className='Login_title'>{title}</h1>
          <h2 className='Login_subtitle'>{mode === "send-verification" ? subtitle : subtitleAlt}</h2>
          <input
            className='Login_input'
            name="email"
            placeholder={mode === "send-verification" ? placeholder : "@ Email"}
            ref={emailRef}
            onInput={() => { if (emailRef.current) { emailRef.current.style.color = "#089950"; emailRef.current.placeholder = placeholder; } }}
            onKeyDown={(e) => { if (e.key === 'Enter') { handleLoginClick() } }}
          />
          {mode === "send-verification" ?
            <div className='Login_form-loginBtn' onClick={handleLoginClick}>
              {loginBtn} ➜ 
            </div>
            :
            <div className='Login_form-loginBtn' onClick={handleLoginClick}>
              {subtitleAlt} 
            </div>
          }
        </div>
        {mode === "send-verification" ? <a href="/signUp" className='Login_signUp'>{signUp}</a> : <a href="/login" className='Login_signUp'>{signIn}</a>}
        
      </div>
    </div>
  );
};

export default Login;
