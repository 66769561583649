import React from 'react';
import AppLastUpdated from './appLastUpdated';
import AppVersion from './appVersion';

interface FooterProps {
    footerCopyright: string;
    footerPrivacy: string;
    footerCookies: string;
    footerDisclaimer: string;
    footerTOS : string;
    handleNavigation: (path: string) => void;
    spmUrl: string;
  }
  const Footer: React.FC<FooterProps> = ({ handleNavigation, footerCopyright,footerPrivacy, footerCookies, footerTOS, footerDisclaimer, spmUrl}) => {

  const handleNavigationClick = (destination: string) => {
    handleNavigation(`/${destination}`);
  };

 
    return (
      <div className="footer">
          <a href="/" className='footer_logo'></a>
          <AppLastUpdated spmUrl={spmUrl}/>
          {/*<AppVersion spmUrl={spmUrl}/>*/}
          <a className="footer_links" onClick={() => handleNavigationClick('Privacy')}>{footerPrivacy}</a>
          <a className="footer_links" onClick={() => handleNavigationClick('cookie-policy')}>{footerCookies}</a>
          <a className="footer_links" onClick={() => handleNavigationClick('terms-of-service')}>{footerTOS}</a>
          <a className="footer_links" onClick={() => handleNavigationClick('Disclaimer')}>{footerDisclaimer}</a>
          {/*<a className="footer_links" onClick={() => handleNavigationClick('login')}>Login</a>*/}
          <p id="footer_copyright">Copyright © 2024 {footerCopyright}</p>
      </div>
    );
  };

export default Footer;