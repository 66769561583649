import React, { useEffect } from 'react';

interface ScriptInjectorProps {
  scriptString: string;
}

const ScriptInjector: React.FC<ScriptInjectorProps> = ({ scriptString }) => {
  useEffect(() => {
    // Sanitize the script string to remove any illegal characters
    const sanitizedScriptString = scriptString.replace(/\u0000/g, '');

    // Function to extract attributes from the script string
    const extractAttributes = (scriptString: string) => {
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = scriptString;
      const tempScript = tempDiv.querySelector('script');
      return tempScript ? tempScript.attributes : null;
    };

    // Create a new script element
    const script = document.createElement('script');
    const attributes = extractAttributes(sanitizedScriptString);

    if (attributes) {
      // Set the attributes on the new script element
      Array.from(attributes).forEach(attr => {
        script.setAttribute(attr.name, attr.value);
      });

      // Set the script content
      const scriptContentMatch = sanitizedScriptString.match(/<script[^>]*>([\s\S]*?)<\/script>/);
      if (scriptContentMatch && scriptContentMatch[1]) {
        script.textContent = scriptContentMatch[1];
      }

      // Insert the script as the first element in the body
      const body = document.body;
      body.insertBefore(script, body.firstChild);

      // Clean up by removing the script when the component unmounts
      return () => {
        body.removeChild(script);
      };
    }
  }, [scriptString]);

  return null;
};

export default ScriptInjector;
