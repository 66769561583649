import React, { useEffect } from 'react';

interface SubscribeFormProps {
  title: string;
  subtitle: string;
}

const SubscribeForm: React.FC<SubscribeFormProps> = ({ title, subtitle }) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://server.fillout.com/embed/v1/";
    script.async = true;
    document.body.appendChild(script);

    // Clean up the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className='subscribeForm_Container'>
        <div className='subscribeForm_TextContainer'>
            <div className='subscribeForm_title'>{title}</div>
            <div className='subscribeForm_subtitle'>{subtitle}</div>
        </div>
      <div
        style={{ width: "100%", height: "500px" }}
        data-fillout-id="b1rEZivikous"
        data-fillout-embed-type="standard"
        data-fillout-inherit-parameters
        data-fillout-dynamic-resize
      ></div>
    </div>
  );
};

export default SubscribeForm;
