import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
  const [countdown, setCountdown] = useState(5);
  const navigate = useNavigate();

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCountdown(prevCount => {
        if (prevCount <= 1) {
          clearInterval(intervalId);
          navigate('/'); // Redirect to the home page after countdown
          return 0;
        }
        return prevCount - 1;
      });
    }, 1000);

    return () => clearInterval(intervalId); // Cleanup interval on unmount
  }, [navigate]);

  return (
    <div className='NotFound_component'>
      <h1 className='NotFound_component-title'>404 - Page Not Found</h1>
      <p className='NotFound_component-text'>Sorry, the page you are looking for does not exist.</p>
      <p className='NotFound_component-text'>Redirecting to the homepage in {countdown} seconds...</p>
    </div>
  );
};

export default NotFound;
