import React, { useRef, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { createChart, IChartApi, ISeriesApi, DeepPartial, ChartOptions, ColorType, MouseEventParams } from 'lightweight-charts';
import { PredictionTable } from './predictionTable';

interface JsonData {
    Date: string;
    Open: string;
    High: string;
    Low: string;
    Close: string;
    Prediction: string;
    Accuracy: string;
    companyName?: string;
    livePrice?: string;
    priceChange?: string;
    percentageChange?: string;
    exchange?: string;
}

interface StockChartProps {
    id?: string;  // Optional prop
    spmUrl: string;
    legendsTxt: string[];
    predictionTxt: string[];
    errorsTxt: string[];
    premiumType: string;
}

const StockChart: React.FC<StockChartProps> = ({ id: propId, spmUrl, legendsTxt, predictionTxt, errorsTxt, premiumType }) => {
    const { id: urlId } = useParams<{ id: string }>();
    const finalId = propId || urlId;
    let decimalPlaces = 2;
    const [data, setData] = useState<JsonData[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [currentPrice, setCurrentPrice] = useState<string>('');
    const [currentPriceChange, setCurrentPriceChange] = useState<string>('');
    const [currentPercentage, setCurrentPercentage] = useState<string>('');
    const [companyName, setCompanyName] = useState<string>('');
    const [tickerExchange, setTickerExchange] = useState<string>('');
    const [todayPrediction, setTodayPrediction] = useState<string>('');
    const [tomorrowPrediction, setTomorrowPrediction] = useState<string>('');
    const chartContainerRef = useRef<HTMLDivElement>(null);
    const chartRef = useRef<IChartApi | null>(null);
    const lineSeriesRef = useRef<ISeriesApi<"Line"> | null>(null);
    const predictionLineRef = useRef<ISeriesApi<"Line"> | null>(null);
    const [isReady, setIsReady] = useState(false);
    const [imageExists, setImageExists] = useState(false);

    type DecimalKeys = 'Close' | 'Prediction';
    const [tooltip, setTooltip] = useState({
        display: false,
        content: '',
        x: 0,
        y: 0
    });
    const tooltipElementRef = useRef<HTMLDivElement | null>(null);

    function getMaxDecimalPlaces(data: JsonData[]): number {
        let maxDecimals = 0;

        data.forEach(item => {
            const keys: (keyof JsonData)[] = ['Close', 'Prediction'];
            keys.forEach(key => {
                const value = item[key];
                if (value && value !== '') {
                    const stringValue = value.toString();
                    const [integerPart, decimalPart] = stringValue.split('.');

                    if (parseInt(integerPart) === 0 && decimalPart) {
                        const matched = decimalPart.match(/^0*[^0]/);
                        if (matched) {
                            const relevantDecimals = matched[0].length + 4;
                            if (relevantDecimals > maxDecimals) {
                                maxDecimals = relevantDecimals;
                            }
                            item[key] = parseFloat(stringValue).toFixed(relevantDecimals);
                        } else {
                            item[key] = parseFloat(stringValue).toFixed(4);
                        }
                    } else if (decimalPart) {
                        const currentDecimals = 2;
                        maxDecimals = currentDecimals;
                        item[key] = parseFloat(stringValue).toFixed(currentDecimals);
                    } else {
                        const currentDecimals = 2;
                        maxDecimals = currentDecimals;
                        item[key] = parseFloat(stringValue).toFixed(0);
                    }
                }
            });
        });

        return maxDecimals;
    }

    function formatDate(dateStr: string, timezone: string = 'America/New_York'): string {
        //console.log("Original date string:", dateStr);

        // Parse the date string into a Date object without assuming it's in UTC
        const dateParts = dateStr.split('-');
        const date = new Date(Number(dateParts[0]), Number(dateParts[1]) - 1, Number(dateParts[2]));

        //console.log("Parsed date object:", date);

        if (isNaN(date.getTime())) {
            console.error("Invalid date:", dateStr);
            return "Invalid Date"; // or handle this case as needed
        }

        // Format the date using the specified time zone
        const options: Intl.DateTimeFormatOptions = {
            year: 'numeric',
            month: 'short',
            day: '2-digit',
            timeZone: timezone
        };
        const formatter = new Intl.DateTimeFormat('en-US', options);
        const formattedDate = formatter.format(date);

        //console.log("Formatted date:", formattedDate);
        return formattedDate;
    }

    const handleCrosshairMoved = (param: MouseEventParams) => {
        if (!param.time || !param.point || !lineSeriesRef.current || !predictionLineRef.current || data.length === 0) {
            setTooltip(prev => ({ ...prev, display: false }));
            return;
        }

        if (!chartContainerRef.current) return;

        const { left, top, right, bottom } = chartContainerRef.current.getBoundingClientRect();
        const x = param.point.x as number;
        const y = param.point.y as number;

        if (x < 0 || y < 0 || x > right - left || y > bottom - top) {
            setTooltip(prev => ({ ...prev, display: false }));
            return;
        }

        let dateStr = '';
        if (typeof param.time === 'number') {
            dateStr = new Date(param.time * 1000).toISOString().substring(0, 10).replace(/-/g, '');
        } else if (typeof param.time === 'string') {
            dateStr = param.time.replace(/-/g, '');
        } else if (typeof param.time === 'object' && 'year' in param.time) {
            dateStr = `${param.time.year}${param.time.month.toString().padStart(2, '0')}${param.time.day.toString().padStart(2, '0')}`;
        }

        const nearestPriceData = data.find(d => d.Date.replace(/-/g, '') === dateStr);
        if (!nearestPriceData) {
            setTooltip(prev => ({ ...prev, display: false }));
            return;
        }

        const formattedDate = formatDate(nearestPriceData.Date);
        const closePrice = nearestPriceData.Close;
        const predictionPrice = nearestPriceData.Prediction;

        const close = parseFloat(closePrice) || 0;
        const prediction = parseFloat(predictionPrice) || 0;
        const accuracy = close === 0 ? (prediction === 0 ? 0 : 100) : 100 - Math.abs((close - prediction) / close) * 100;

        const accuracyColor = accuracy < 97 ? 'red' : '#468b40';

        const closePriceContent = closePrice ? `<span class="stockChart_tooltip-close">$${close.toFixed(decimalPlaces)}</span>` : '';
        const predictionPriceContent = predictionPrice ? `<span class="stockChart_tooltip-prediction">${legendsTxt[1]}: $${prediction.toFixed(decimalPlaces)}</span>` : '';
        const accuracyContent = prediction === 0 ? '' : (close === 0 ? ''/*`<span class="stockChart_tooltip-accuracy" style="color: ${accuracyColor}">Confidence: ${accuracy.toFixed(2)}%</span>` */
            : `<span class="stockChart_tooltip-accuracy" style="color: ${accuracyColor}">${legendsTxt[2]}: ${accuracy.toFixed(2)}%</span>`);
        setTooltip({
            display: true,
            content: `${closePriceContent}${predictionPriceContent}${accuracyContent}<span class="stockChart_tooltip-date">${formattedDate}</span>`,
            x: param.point.x + 20,
            y: param.point.y
        });
    };

    const getFilteredDataByPremiumType = (data: JsonData[]): JsonData[] => {
        const today = new Date(new Date().toLocaleString('en-US', { timeZone: 'America/New_York' }));
        const todayIndex = data.findIndex(d => new Date(d.Date) >= today);
        
        let visibleDays: number;
        switch (premiumType) {
            case 'free':
                visibleDays = 5;
                break;
            case 'pro':
            case 'plus':
            case 'G':
                return data; // Return all data for plus and admin users
            default:
                visibleDays = 3; // Default to free tier if unknown
        }

        if (todayIndex === -1) {
            // If today is not in the dataset, return the last 'visibleDays' days
            return data.slice(-visibleDays);
        }

        const startIndex = Math.max(0, todayIndex - 30); // Show up to 30 days of historical data
        const endIndex = Math.min(data.length, todayIndex + visibleDays);
        return data.slice(startIndex, endIndex);
    };

    useEffect(() => {
        async function fetchData() {
            if (!finalId) return;

            try {
                const response = await fetch(`../DATA/Organized/${finalId}/${finalId}.json?ts=${new Date().getTime()}`);
            /*console.log("Response status:", response.status);
            console.log("Response headers:", response.headers);*/
            if (!response.ok) {
                //console.log("Response text:", await response.text());
                throw new Error(`HTTP error! status: ${response.status}`);
            }
                let jsonData = await response.json() as JsonData[];
                jsonData = jsonData.map(item => ({
                    ...item,
                    Date: item.Date
                })).sort((a, b) => a.Date.localeCompare(b.Date));
                
                // Filter the data based on premium type
                const filteredData = getFilteredDataByPremiumType(jsonData);
                setData(filteredData);
                setLoading(false);

                const today = new Date(new Date().toLocaleString('en-US', { timeZone: 'America/New_York' }));
                const tomorrow = new Date(today);
                tomorrow.setDate(today.getDate() + 1);

                /*
                console.log("Today (EST):", today);
                console.log("Tomorrow (EST):", tomorrow);
                */

                const todayDateStr = today.toLocaleDateString('en-CA', { timeZone: 'America/New_York' }).replace(/-/g, '');
                const tomorrowDateStr = tomorrow.toLocaleDateString('en-CA', { timeZone: 'America/New_York' }).replace(/-/g, '');

                /*
                console.log("Today Date String:", todayDateStr);
                console.log("Tomorrow Date String:", tomorrowDateStr);

                */
                const todayPredictionData = jsonData.find(d => d.Date.replace(/-/g, '') === todayDateStr);
                const tomorrowPredictionData = jsonData.find(d => d.Date.replace(/-/g, '') === tomorrowDateStr);

                /*
                console.log("Today Prediction Data:", todayPredictionData);
                console.log("Tomorrow Prediction Data:", tomorrowPredictionData);

                */
                const maxDecimals = getMaxDecimalPlaces(jsonData);
                decimalPlaces = maxDecimals;

                setTodayPrediction(todayPredictionData ? parseFloat(todayPredictionData.Prediction).toFixed(maxDecimals) : '');
                setTomorrowPrediction(tomorrowPredictionData ? parseFloat(tomorrowPredictionData.Prediction).toFixed(maxDecimals) : '');

            } catch (e) {
                setError(`Failed to fetch data: ${e instanceof Error ? e.message : String(e)}`);
                setLoading(false);
            }
            try {
                /*console.log("Fetching: ");
                console.log(spmUrl);*/
                let liveDataResponse;
                if (finalId.includes("=i")){
                    liveDataResponse = await fetch(`${spmUrl}php/yahooCrawler.php?ticker=^${finalId.split("=i")[0]}`);
                }
                else{
                    liveDataResponse = await fetch(`${spmUrl}php/yahooCrawler.php?ticker=${finalId}`);
                }
                
                if (!liveDataResponse.ok) {
                    throw new Error(`HTTP error! status: ${liveDataResponse.status}`);
                }
                const liveData = await liveDataResponse.json() as JsonData;

                //console.log(liveData);

                // Extract and set values directly
                setCompanyName(liveData.companyName || '');
               // console.log(liveData.companyName);

                setCurrentPrice(liveData.livePrice || '');
               // console.log(liveData.livePrice);

                setCurrentPriceChange(liveData.priceChange || '');
             //   console.log(liveData.priceChange);

                setCurrentPercentage(liveData.percentageChange || '');
             //   console.log(liveData.percentageChange);

                setTickerExchange(liveData.exchange || '');
              //  console.log(liveData.exchange);

            } catch (e) {
                setError(`Failed to fetch data: ${e instanceof Error ? e.message : String(e)}`);
            }
        }

        fetchData();
     //   console.log("Final ID:", finalId);
    }, [finalId]);

    useEffect(() => {
        if (!chartContainerRef.current || data.length === 0) return;
        const maxDecimals = getMaxDecimalPlaces(data);
        decimalPlaces = maxDecimals;

        if (!chartRef.current) {
            const chartOptions: DeepPartial<ChartOptions> = {
                layout: {
                    textColor: 'rgba(255, 255, 255, 0.9)',
                    background: {
                        type: ColorType.Solid,
                        color: '#131722'
                    }
                },
                grid: {
                    vertLines: {
                        visible: false,
                    },
                    horzLines: {
                        visible: false,
                    }
                },
                rightPriceScale: {
                    mode: 0,
                    autoScale: true,
                    borderVisible: true,
                    borderColor: 'rgba(197, 203, 206, 0.8)',
                },
                timeScale: {
                    borderVisible: true,
                    borderColor: 'rgba(197, 203, 206, 0.8)',
                    timeVisible: true,
                    secondsVisible: false,
                    fixLeftEdge: true,
                    fixRightEdge: true,
                    barSpacing: 10,
                    rightOffset: 10,
                },
                localization: {
                    locale: 'en-US',
                },
                crosshair: {
                    vertLine: {
                        color: 'rgba(197, 203, 206, 0.8)',
                        width: 1,
                        style: 0,
                        labelVisible: false,
                    },
                    horzLine: {
                        visible: false,
                        labelVisible: false,
                    }
                },
                autoSize: true,
            };
            const newChart = createChart(chartContainerRef.current, chartOptions);
            const lineSeries = newChart.addLineSeries({
                color: '#2962FF',
                lineWidth: 2,
                crosshairMarkerVisible: false,
                priceFormat: {
                    type: 'custom',
                    formatter: (price: number) => `$${price.toFixed(decimalPlaces)}`,
                },
            });
            const predictionLine = newChart.addLineSeries({
                color: 'orange',
                lineWidth: 2,
                crosshairMarkerVisible: false,
                priceFormat: {
                    type: 'custom',
                    formatter: (price: number) => `$${price.toFixed(decimalPlaces)}`,
                },
            });
            lineSeries.setData(data.map(d => ({ time: d.Date.toString(), value: parseFloat(d.Close) })).filter(d => d.value));
            predictionLine.setData(data.map(d => ({ time: d.Date.toString(), value: parseFloat(d.Prediction) })).filter(d => d.value));
            chartRef.current = newChart;
            lineSeriesRef.current = lineSeries;
            predictionLineRef.current = predictionLine;
            if (data.length > 0) {
                setIsReady(true);
            }
        } else {
            lineSeriesRef.current?.setData(data.map(d => ({ time: d.Date, value: parseFloat(d.Close) })).filter(d => d.value));
            predictionLineRef.current?.setData(data.map(d => ({ time: d.Date, value: parseFloat(d.Prediction) })).filter(d => d.value));
        }

        chartRef.current.subscribeCrosshairMove(handleCrosshairMoved);
    }, [data]);

    useEffect(() => {
        if (chartContainerRef.current) {
            if (!tooltipElementRef.current) {
                const toolTip = document.createElement('div');
                toolTip.classList.add('stockChart_tooltip');
                chartContainerRef.current.appendChild(toolTip);
                tooltipElementRef.current = toolTip;
            }

            const toolTip = tooltipElementRef.current;
            toolTip.style.display = tooltip.display ? 'flex' : 'none';
            toolTip.innerHTML = tooltip.content;
            toolTip.style.left = `${tooltip.x}px`;
            toolTip.style.top = `${tooltip.y}px`;
        }
    }, [tooltip]);

    useEffect(() => {
        const img = new Image();
        img.src = `../DATA/Organized/${finalId}/${finalId}.svg`;

        const handleImageLoad = () => setImageExists(true);
        const handleImageError = () => setImageExists(false);

        img.onload = handleImageLoad;
        img.onerror = handleImageError;

        return () => {
            img.onload = null;
            img.onerror = null;
        };
    }, [finalId]);

    const isPositive = (priceChange: string | null): boolean => {
        if (priceChange === null || priceChange === '') {
            return false;
        }
        else if (priceChange.startsWith('(-')) {
            return false;

        }
        return !priceChange.startsWith('-');
    };

    /*const getFilteredData = (): JsonData[] => {
        const today = new Date(new Date().toLocaleString('en-US', { timeZone: 'America/New_York' }));
        const todayDateStr = today.toISOString().split('T')[0];
     //   console.log(`Today's date: ${todayDateStr}`);

        // Convert the dates in data to the same format
        const dataDates = data.map(d => d.Date);

        // Find the closest past date
        let pastIndex = -1;
        for (let i = dataDates.length - 1; i >= 0; i--) {
            if (dataDates[i] <= todayDateStr) {
                pastIndex = i;
                break;
            }
        }

        // Find the closest future date
        let futureIndex = -1;
        for (let i = 0; i < dataDates.length; i++) {
            if (dataDates[i] >= todayDateStr) {
                futureIndex = i;
                break;
            }
        }

        // Find today's date
        const todayIndex = dataDates.indexOf(todayDateStr);

        // Define pastDates and futureDates with explicit types
        let pastDates: JsonData[] = [];
        let futureDates: JsonData[] = [];

        if (todayIndex !== -1) {
            pastDates = data.slice(Math.max(todayIndex - 2, 0), todayIndex);
            futureDates = data.slice(todayIndex + 1, todayIndex + 3);
        } else {
            if (pastIndex !== -1) pastDates = data.slice(Math.max(pastIndex - 1, 0), pastIndex + 1);
            if (futureIndex !== -1) futureDates = data.slice(futureIndex, futureIndex + 2);
        }

        // Combine past and future dates, ensuring no duplicates and sorting them
        const combinedDates = [...pastDates, ...(todayIndex !== -1 ? [data[todayIndex]] : []), ...futureDates];
        const uniqueDates = combinedDates.filter((v, i, a) => a.findIndex(t => t.Date === v.Date) === i).sort((a, b) => new Date(a.Date).getTime() - new Date(b.Date).getTime());

        return uniqueDates;
    };

    const formatDateForDisplay = (dateStr: string): string => {
        const date = new Date(dateStr + 'T00:00:00-05:00'); // Treat the date as EST
      //  console.log(`Date string is: ${dateStr}`);
        const options: Intl.DateTimeFormatOptions = {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
            timeZone: 'America/New_York' // Force EST to avoid local time zone conversion issues
        };
        return date.toLocaleDateString('en-US', options);
    };*/

    return (
        <div id="stockChart_container">
            <div className='stockChart_tickerInfoContainer'>
                {imageExists ? (
                    <img
                        className="stockChart_tickerLogo"
                        src={`../DATA/Organized/${finalId}/${finalId}.svg`}
                        alt=""
                    />
                ) : (
                    ""
                )}
                <h2 className='stockChart_companyName'>{companyName ? companyName : finalId}</h2>
            </div>
            <div className='stockChart_realTimePrice-container'>
                <h3 className='stockChart_realTimePrice-value'>{currentPrice ? currentPrice : ''}</h3>

                <h3 className={
                    currentPriceChange !== null && isPositive(currentPriceChange)
                        ? 'stockChart_realTimePrice-priceDifference_Positive'
                        : 'stockChart_realTimePrice-priceDifference_Negative'
                }>{currentPriceChange ? currentPriceChange : ''}</h3>
                <h3 className={
                    currentPercentage !== null && isPositive(currentPercentage)
                        ? 'stockChart_realTimePrice-percentageDifference_Positive'
                        : 'stockChart_realTimePrice-percentageDifference_Negative'
                }>{currentPercentage ? currentPercentage : ''}</h3>

            </div>
            <h3 className='stockChart_exchange'>{tickerExchange ? tickerExchange : ''}</h3>
            <div id="stockChart" ref={chartContainerRef}></div>
            {premiumType !== 'plus' && premiumType !== 'admin' && (
                <div className="stockChart_upgrade-message">
                    <p style={{color: "#fff", textAlign: "center"}}>Upgrade to <b>{premiumType === 'free' ? 'Pro' : 'Plus'}</b> to see more data!</p>
                </div>
            )}
            <div className='stockChart_legend'>
                <div className='stockChart_legend-labels'><span style={{ backgroundColor: '#2962FF', color: '#2962FF' }}></span> {legendsTxt[0]}</div>
                <div className='stockChart_legend-labels'><span style={{ backgroundColor: 'orange', color: 'orange' }}></span> {legendsTxt[1]}</div>
            </div>
            <div className='stockChart_prediction'>
                <PredictionTable data={data}/>
                {/*<div className="stockChart_prediction-table">
                    <div className="stockChart_prediction-table_column">
                        {getFilteredData().map((row, index) => {
                            console.log(row); // Debugging line to check data
                            let accuracy: string | null = null;

                            if (parseFloat(row.Close) === 0) {
                                accuracy = parseFloat(row.Prediction) === 0 ? '0' : null;
                            } else {
                                accuracy = (100 - Math.abs((parseFloat(row.Close) - parseFloat(row.Prediction)) / parseFloat(row.Close)) * 100).toFixed(2);
                            }

                            const accuracyClass = accuracy !== null
                                ? (accuracy == 'NaN'
                                    ? 'stockChart_prediction-table_cell'
                                    : (parseFloat(accuracy) > 97
                                        ? 'accuracy-green'
                                        : 'accuracy-red'))
                                : 'stockChart_prediction-table_cell';

                            return (
                                <div key={index} className={index === 2 ? "stockChart_prediction-table_subColumn-today" : "stockChart_prediction-table_subColumn"}>
                                    <div className="stockChart_prediction-table_cell-Header">{formatDateForDisplay(row.Date)}</div>
                                    <div className="stockChart_prediction-table_cell-prediction">{row.Prediction !== "" ? `$${row.Prediction}` : ''}</div>
                                    <div className="stockChart_prediction-table_cell-close">{row.Close !== "" ? `$${row.Close}` : ''}</div>
                                    <div className={`stockChart_prediction-table_cell-${accuracyClass}`}>
                                        {accuracy !== null && accuracy !== "NaN" ? <p>Accuracy</p> : ''}
                                        {accuracy !== null && accuracy !== "NaN" ? `${accuracy}%` : ''}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>*/}
            </div>
            
        </div>
    );
};

export default StockChart;