import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

interface HeroProps {
  title: string;
  subtitle: string;
}
const Hero: React.FC<HeroProps> = ({ title, subtitle}) => {

  return (
    <div className="hero">
      <h1>{title}</h1>
      <h2>{subtitle}</h2>
      <a href='/login'>Login</a>
    </div>
  );
}

export default Hero;