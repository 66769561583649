import React, { useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUser } from './userContext';

interface LoginVerificationProps {
  title: string;
  subtitle: string;
  loginBtn: string;
  spmUrl: string;
  resendBtn: string;
}

const LoginVerification: React.FC<LoginVerificationProps> = ({ title, subtitle, loginBtn, spmUrl, resendBtn }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const email = location.state?.email;
  const { setVerificationRequired, setUserDetails } = useUser();

  const inputRefs = useRef<Array<HTMLInputElement | null>>(Array(6).fill(null));

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const inputValue = e.target.value;
    if (inputValue.length === 1 && index < 5) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === 'Backspace' && !inputRefs.current[index]?.value && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const paste = e.clipboardData.getData('text');
    if (paste.length === 6) {
      paste.split('').forEach((char, index) => {
        if (inputRefs.current[index]) {
          inputRefs.current[index]!.value = char;
        }
      });
      inputRefs.current[5]?.focus();
    }
    e.preventDefault();
  };

  const handleSubmit = async () => {
    const code = inputRefs.current.map(ref => ref?.value).join('');
    if (code.length !== 6) {
      alert('Please enter the 6-digit code');
      return;
    }

    console.log('Code:', code); // Log the code for debugging

    try {
      const response = await fetch(`${spmUrl}php/verify.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ code, email }),
      });

      const data = await response.json();

      if (data.message === 'Code verified.') {
        const responseTrackVisit = await fetch(`${spmUrl}php/track_visit.php`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ userId: data.user_id, generateSessionId: true }),
        });

        const dataTrackVisit = await responseTrackVisit.json();
        const sessionId = dataTrackVisit.sessionId;

        if (sessionId) {
          localStorage.setItem('isAuthenticated', 'true');
          localStorage.setItem('userId', data.user_id);
          localStorage.setItem('premiumType', data.premium_type);
          localStorage.setItem('sessionId', sessionId);

          setVerificationRequired(false);
          setUserDetails(prevDetails => ({
            ...prevDetails,
            id: data.user_id,
            premium_type: data.premium_type
          }));
          navigate('/dashboard');
        } else {
          console.error('Session ID not returned from track_visit.php');
          alert('An error occurred while generating the session ID.');
        }
      } else {
        alert(data.message);
      }
    } catch (error) {
      alert('An error occurred while verifying the code.');
      console.log(error);
    }
  };

  const resendCode = async () => {
    if (!email) {
      alert('Please enter your email');
      navigate('/login');
      return;
    }

    try {
      const response = await fetch(`${spmUrl}php/send-verification.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (data.message === 'Verification code sent.') {
        alert('New verification code sent');
        inputRefs.current.forEach((inputRef) => {
          if (inputRef) {
            inputRef.value = '';
          }
        });
      } 
    } catch (error) {
      alert('An error occurred while sending the verification email.');
      console.log(error);
    }
  };

  return (
    <div className='LoginVerification_Component'>
      <div className='LoginVerification_Container'>
        <div className='LoginVerification_Form'>
          <a href="/" className='LoginVerification_logo'></a>
          <h1 className='LoginVerification_title'>{title}</h1>
          <h3 className='LoginVerification_subtitle'>{subtitle} <br /><span className='LoginVerification_subtitle-email'>{email}</span></h3>
          <div className='LoginVerification_input-container'>
            {inputRefs.current.map((_, index) => (
              <input
                key={index}
                ref={el => inputRefs.current[index] = el}
                type="text"
                maxLength={1}
                className='LoginVerification_input-verification'
                onChange={(e) => handleChange(e, index)}
                onKeyDown={(e) => { handleKeyDown(e, index); if (e.key === 'Enter') { handleSubmit() } }}
                onPaste={handlePaste}
              />
            ))}
          </div>
          <div className='LoginVerification_form-loginBtn' onClick={handleSubmit}>{loginBtn} ➜</div>
          <div className='LoginVerification_resendCode' onClick={resendCode}>{resendBtn}</div>
        </div>
      </div>
    </div>
  );
};

export default LoginVerification;

// Function to generate a random string
const bin2hex = (s: string) => s.split('').map(c => ('0' + c.charCodeAt(0).toString(16)).slice(-2)).join('');
const random_bytes = (length: number) => {
  const result = [];
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (let i = 0; i < length; i++) {
    result.push(characters.charAt(Math.floor(Math.random() * characters.length)));
  }
  return result.join('');
}
