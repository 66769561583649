import React, { useState, useEffect } from 'react';

interface AppLastUpdatedProps {
  spmUrl: string;
}

interface VersionInfo {
  version: string;
  lastUpdated: string;
}

interface LastUpdated {
  lastUpdated: string;
}

const AppLastUpdated: React.FC<AppLastUpdatedProps> = ({ spmUrl }) => {
  const [versionInfo, setVersionInfo] = useState<VersionInfo | null>(null);
  const [lastUpdated, setLastUpdated] = useState<LastUpdated | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const timestamp = new Date().getTime();
      try {
        const [versionResponse, lastUpdatedResponse] = await Promise.all([
          /*fetch(`${spmUrl}version.json?t=${timestamp}`),
          fetch(`${spmUrl}lastUpdate.json?t=${timestamp}`)*/
          fetch(`/version.json?t=${timestamp}`),
          fetch(`/lastUpdate.json?t=${timestamp}`)
        ]);

        if (!versionResponse.ok) throw new Error(`HTTP error! status: ${versionResponse.status}`);
        if (!lastUpdatedResponse.ok) throw new Error(`HTTP error! status: ${lastUpdatedResponse.status}`);

        const versionData = await versionResponse.json();
        const lastUpdatedData = await lastUpdatedResponse.json();

        setVersionInfo(versionData);
        setLastUpdated(lastUpdatedData);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Last update information not available.');
      }
    };

    fetchData();
  }, [spmUrl]);

  if (error) return <div className="appVersion"><p>{error}</p></div>;
  if (!versionInfo || !lastUpdated) return <div className="appVersion loading">Loading update information...</div>;

  return (
    <div className="appVersion">
      <p>V: {versionInfo.version}</p>
      <p>Predictions Updated: {new Date(lastUpdated.lastUpdated).toLocaleString()}</p>
      <p style={{color: "#1e1e1e", margin: 0}}>Predictions are updated on trading days only</p>
    </div>
  );
};

export default AppLastUpdated;