import React, { useState, useRef, useEffect } from 'react';
// Your existing SearchBarProps interface
interface SearchBarProps {
  placeholder: string;
  spmUrl: string;
  handleNavigation: (path: string) => void;
  currentLocation: { pathname: string };
  premiumType : string;
}

// Additional interfaces and types
interface TickerData {
  symbol: string;
  Date: string;
  Open: string;
  High: string;
  Low: string;
  Close: string;
  Prediction: string;
  Accuracy: string;
}

// Helper functions
const fetchTickerData = async (spmUrl: string): Promise<TickerData[]> => {
  const response = await fetch(`${spmUrl}/php/jsonFileLocator.php`);
  return await response.json();
};


// Main component
const SearchBar: React.FC<SearchBarProps> = ({ placeholder, spmUrl, handleNavigation, currentLocation, premiumType }) => {
  const inputField = useRef<HTMLInputElement>(null);
  const resultsContainer = useRef<HTMLUListElement>(null);
  const [query, setQuery] = useState('');
  const [tickers, setTickers] = useState<string[]>([]);
  const [results, setResults] = useState<string[]>([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverlayVisible, setIsOverlayVisible] = useState(true);

  useEffect(() => {
    const fetchTickers = async () => {
      try {
        const data = await fetchTickerData(spmUrl);
        console.log(`Fetched data: `, data);
        
        // Extract the symbols from the objects
        const tickerSymbols: string[] = data.map(ticker => ticker.toString());
        console.log(`tickerSymbols: `, tickerSymbols);
        setTickers(tickerSymbols);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchTickers();
  }, [spmUrl]);
  
  
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.toUpperCase(); // Convert input to uppercase
    setQuery(value);
  
    if (value.length > 0) {
      const filteredTickers = tickers.filter(ticker =>
        ticker.startsWith(value) // Directly compare strings
      );
      console.log(`Filtered tickers: `, filteredTickers);
      setResults(filteredTickers);
      setIsExpanded(true);
    } else {
      setResults([]);
      setIsExpanded(false);
    }
  };
  
  
  

  useEffect(() => {
    if (inputField.current) {
      inputField.current.placeholder = `🔍 ${placeholder}`;
    }
  }, [placeholder]);

  useEffect(() => {
    const container = resultsContainer.current;

    if (container) {
      if (isExpanded && results.length > 0) {
        const liElements = container.querySelectorAll('li');
        const totalHeight = Array.from(liElements).reduce((acc, li) => acc + li.scrollHeight, 0);

        container.style.height = '0';
        requestAnimationFrame(() => {
          container.style.height = `${totalHeight}px`;
        });

        const transitionEnd = () => {
          container.style.height = `${totalHeight}px`;
          container.removeEventListener('transitionend', transitionEnd);
        };

        container.addEventListener('transitionend', transitionEnd);
      } else {
        container.style.height = '0';
        requestAnimationFrame(() => {
          container.style.height = '0';
        });
      }
    }
  }, [results, isExpanded]);

  const handleResultClick = (ticker: string) => {
    handleNavigation(`/v1/${ticker}`);
    setQuery('');
    setResults([]);
    setIsExpanded(false);
    setIsOverlayVisible(true);
  };

  const handleOverlayClick = () => {
    setIsOverlayVisible(false);
  };

  useEffect(() => {
    const handleRouteChange = () => {
      setIsOverlayVisible(true);
    };

    window.addEventListener('popstate', handleRouteChange);

    return () => {
      window.removeEventListener('popstate', handleRouteChange);
    };
  }, []);

  const handleAdvancedSearchClick = () => {
    handleNavigation('/advanced-search');
  };

  
  
  

  return (
    <div className="searchBar_container">
      {isOverlayVisible && premiumType == "free" && (
        <a href='https://stighoazon.com/4/7822902' className="searchBar_ad" onClick={handleOverlayClick} target="_blank" rel="noopener">
        </a>
      )}
      <div className="searchBar_inputContainer">
        <input
          id="searchBar"
          placeholder={`🔍 ${placeholder}`}
          ref={inputField}
          value={query}
          onChange={handleInputChange}
        />
        {currentLocation.pathname !== '/advanced-search' 
        ? <a className="searchBar_advancedButton" onClick={handleAdvancedSearchClick}>
        Advanced
      </a> : ""
      }
      </div>
      <ul className="searchBar_searchResults" ref={resultsContainer}>
        {results.map((ticker, index) => (
          <li key={index} onClick={() => handleResultClick(ticker)}>
            {ticker}
          </li>
        ))}
      </ul>
      {/*<div className="searchBar_searchType-container">
        <h3 className="searchBar_searchType-subTitles">Search By</h3>
        <ul className="searchBar_searchType-option-container">
          <li className="searchBar_searchType-optionBtn" onClick={handlePredictedGrowthClick}>Predicted Growth</li>
          <li className="searchBar_searchType-optionBtn" onClick={handlePredictedLossClick}>Predicted Loss</li>
        </ul>        
      </div>
      <div className="searchBar_searchType-container">
        <h3 className="searchBar_searchType-subTitles">In the Next</h3>
        <ul className="searchBar_searchType-option-container">
        <select className="searchBar_searchType-option-daySelector" value={selectedDays} onChange={handleChange}>
        {Array.from({ length: 14 }, (_, index) => index + 1).map((day) => (
          <option key={day} value={day}>
            {day > 1 ? day + " Trading Days" : "Trading Day"}
          </option>
        ))}
      </select>
        </ul>
      </div>
      <div className="searchBar_searchType-resultsContainer">
        <div className='searchBar_searchType-results_tableContainer'>
        <div className="searchBar_searchType-results_table-header">Ticker</div>
        <div className="searchBar_searchType-results_table-header">Close</div>
        <div className="searchBar_searchType-results_table-header">Predicted Close</div>
        <div className="searchBar_searchType-results_table-header">Predicted $ Change</div>
        <div className="searchBar_searchType-results_table-header">Predicted % Change</div>
        </div>
        {predictedData.map((data, index) => (
          
  <div className="searchBar_searchType-results_ticker-Container" key={index}>
    <div className="searchBar_searchType-results_tickerDetails-container" onClick={() => handleResultClick(data.ticker)}>
      <img
        className="searchBar_searchType-results_tickerDetails-Img"
        src={`../DATA/Organized/${data.ticker}/${data.ticker}.svg`}
        alt=''
        onError={(e) => {
          e.currentTarget.onerror = null; // Prevent infinite loop if fallback fails
          e.currentTarget.src = ''; // Fallback image path
          e.currentTarget.style.display = "none"; // Fallback image path
        }}
      />
      <h5 className="searchBar_searchType-results_tickerDetails-ticker">
        {data.ticker}
      </h5>
    </div>
    <div className="searchBar_searchType-results_tickerDetails-marketClose">
      {data.todayClose.toFixed(2)}
    </div>
    <div className="searchBar_searchType-results_tickerDetails-spmClose">
      {data.futureClose.toFixed(2)}
    </div>
    <div className={`searchBar_searchType-results_tickerDetails-spmChange-${data.changeValue >= 0 ? 'positive' : 'negative'}`}>
      {data.changeValue.toFixed(2)}
    </div>
    <div className={`searchBar_searchType-results_tickerDetails-spmPerChange-${data.changePercentage >= 0 ? 'positive' : 'negative'}`}>
      {data.changePercentage.toFixed(2)}%
    </div>
  </div>
))}

      </div>*/}
    </div>
  );
};

export default SearchBar;
