import React, { useState, useRef, useEffect } from 'react';

interface tickersGrowthTableProps {
    spmUrl: string;
    handleNavigation: (path: string) => void;
    currentLocation: { pathname: string };
    premiumType : string;
  }
  
  // Additional interfaces and types
  interface TickerData {
    symbol: string;
    Date: string;
    Open: string;
    High: string;
    Low: string;
    Close: string;
    Prediction: string;
    Accuracy: string;
  }
  
  interface PredictionResult {
    ticker: string;
    todayClose: number;
    futureClose: number;
    changeValue: number;
    changePercentage: number;
  }
  
  // Helper functions
  const fetchTickerData = async (spmUrl: string): Promise<TickerData[]> => {
    const response = await fetch(`${spmUrl}/php/jsonFileLocator.php`);
    return await response.json();
  };
  
  const getTodayClose = (
    tickerData: TickerData[],
    targetDate: string,
    tickerName: string,
  ): { date: string; close: number } | null => {
    // Sort the data by date in descending order (most recent first)
    const sortedData = tickerData.sort((a, b) => new Date(b.Date).getTime() - new Date(a.Date).getTime());
  
    console.log(`Searching for close price on or before date: ${targetDate}`);
  
    // Find the most recent data point on or before the target date
    let foundTicker = sortedData.find(data => {
      const dataDate = new Date(data.Date);
      const target = new Date(targetDate);
      const daysDifference = (target.getTime() - dataDate.getTime()) / (1000 * 3600 * 24);
      const closeValue = parseFloat(data.Close);
  
      return (
        data.Date <= targetDate &&
        daysDifference <= 3 &&
        !isNaN(closeValue) &&
        closeValue > 0
      );
    });
  
    if (foundTicker) {
      console.log(`Found valid data for ${tickerName} on date: ${foundTicker.Date}`);
      const closeValue = parseFloat(foundTicker.Close);
  
      return {
        date: foundTicker.Date,
        close: closeValue,
      };
    }
  
    console.log(`No valid recent data found for ${tickerName}`);
    return null;
  };
  
  
  
  const getFutureDate = (startDate: string, tradingDays: number, tickerDataArray: TickerData[]): string => {
    // Sort the data array by date
    const sortedData = tickerDataArray.sort((a, b) => new Date(a.Date).getTime() - new Date(b.Date).getTime());
  
    // Find the index of the start date
    const startIndex = sortedData.findIndex(data => data.Date >= startDate);
    if (startIndex === -1) {
      console.warn(`Start date ${startDate} not found in the data. Using the last available date.`);
      return sortedData[sortedData.length - 1].Date;
    }
  
    // Count forward the specified number of trading days
    let futureIndex = startIndex;
    let daysCount = 0;
    while (daysCount < tradingDays && futureIndex < sortedData.length - 1) {
      futureIndex++;
      daysCount++;
    }
  
    // If we've reached the end of the array before counting all trading days,
    // use the last available date
    if (futureIndex >= sortedData.length) {
      futureIndex = sortedData.length - 1;
    }
  
    const futureDate = sortedData[futureIndex].Date;
    console.log(`Future date for ${tradingDays} trading days from ${startDate}: ${futureDate}`);
  
    return futureDate;
  };
  
  const getPredictionByDate = (
    tickerDataArray: TickerData[],
    targetDate: string
  ): number => {
    const foundTicker = tickerDataArray.find((ticker) => ticker.Date === targetDate);
    console.log(`Found Ticker is: `, foundTicker);
    
    if (foundTicker) {
      return parseFloat(foundTicker.Prediction) || 0;
    }
    
    return 0; // Return 0 if no prediction is found for the given date
  };
  
  
  // Main component
  const SearchBar: React.FC<tickersGrowthTableProps> = ({ spmUrl, handleNavigation, currentLocation, premiumType }) => {
    const [tickers, setTickers] = useState<string[]>([]);
    const [results, setResults] = useState<string[]>([]);
    const [selectedDays, setSelectedDays] = useState(1);
    const [predictedData, setPredictedData] = useState<PredictionResult[]>([]);
  
    useEffect(() => {
      const fetchTickers = async () => {
        try {
          const data = await fetchTickerData(spmUrl);
          console.log(`Fetched data: `, data);
          
          // Extract the symbols from the objects
          const tickerSymbols: string[] = data.map(ticker => ticker.toString());
          console.log(`tickerSymbols: `, tickerSymbols);
          setTickers(tickerSymbols);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
    
      fetchTickers();
    }, [spmUrl]);
    
  
    const handleResultClick = (ticker: string) => {
      handleNavigation(`/v1/${ticker}`);
    };
  
  

  
    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
      setSelectedDays(Number(event.target.value));
    };
  
    const handlePredictedChangeClick = async (tableMode : string) => {
      //let tickerSymbol;
      try {
        const tickers = await fetchTickerData(spmUrl); // Assuming this returns an array of TickerData objects
    
        const batchSize = 20; // Number of tickers to fetch in each batch
        const tickerBatches = [];
        for (let i = 0; i < tickers.length; i += batchSize) {
          tickerBatches.push(tickers.slice(i, i + batchSize));
        }
    
        const predictions = [];
    
        for (const batch of tickerBatches) {
          const tickerPromises = batch.map(async (tickerData: TickerData, index: number) => {
            const tickerSymbol = tickerData.toString();
    
            // Skip tickers that contain the caret symbol
            if ( tickerSymbol.includes('^') || tickerSymbol.includes('(delisted)')) {
              return null; // Skip this ticker
            }
    
            const fetchUrl = `../DATA/Organized/${tickerSymbol}/${tickerSymbol}.json?ts=${new Date().getTime()}`;
            const response = await fetch(fetchUrl);
            const tickerDataArray = await response.json();
    
            if (!Array.isArray(tickerDataArray) || tickerDataArray.length === 0) {
              console.warn(`Ticker data is not an array or is empty for ticker: ${tickerSymbol}`);
              return null; // Skip this ticker
            }
    
            // Use the current date as the target date
            const todayDate = new Date().toISOString().split('T')[0];
            const today = getTodayClose(tickerDataArray, todayDate, tickerSymbol);
            console.log(`tickerData Array : `, tickerDataArray);
    
            if (!today) {
              console.warn(`No data found for today's date or the last 3 days for ticker: ${tickerSymbol}`);
              return null; // Skip this ticker if no data is found
            }
    
            const futureDate = getFutureDate(today.date, selectedDays, tickerDataArray);
            const futurePrediction = getPredictionByDate(tickerDataArray, futureDate);
            console.log(`Data array is: `, tickerDataArray[index].Close);
            console.log(`Today close is: ${today.close}`);
            // Ensure that today.close and futurePrediction are valid numbers
            const todayClose = isNaN(today.close) ? 0 : today.close;
            const futureClose = isNaN(futurePrediction) ? 0 : futurePrediction;
            const changeValue = futureClose - todayClose;
            const changePercentage = todayClose > 0 ? (changeValue / todayClose) * 100 : todayClose <0 ? (changeValue / todayClose) * -100 : changeValue * 100;
    
            return {
              ticker: tickerSymbol,
              todayClose: todayClose,
              futureClose: futureClose,
              changeValue: changeValue,
              changePercentage: isNaN(changePercentage) ? 0 : changePercentage,
            };
          });
    
          const tickerDataArray = await Promise.all(tickerPromises);
    
          const batchPredictions = tickerDataArray
            .filter(tickerData => tickerData !== null) // Filter out null values (skipped tickers)
            .map(tickerData => tickerData as PredictionResult); // Type assertion to PredictionResult
    
          predictions.push(...batchPredictions);
        }
  
        
        if(tableMode == "Growth"){
            // Filter out predictions with non-positive changes and sort the rest by changeValue
            const positivePredictions = predictions
            .filter(prediction => prediction.changePercentage > 0) // Only positive changes
            .sort((a, b) => b.changePercentage - a.changePercentage); // Sort by changeValue descending
            console.log(`Predictions = ${positivePredictions}`);
            setPredictedData(positivePredictions);
        }

        if(tableMode == "Drop"){
            // Filter out predictions with non-positive changes and sort the rest by changeValue
            const positivePredictions = predictions
            .filter(prediction => prediction.changePercentage < 0) // Only positive changes
            .sort((a, b) => a.changePercentage - b.changePercentage); // Sort by changeValue descending
            console.log(`Predictions = ${positivePredictions}`);
            setPredictedData(positivePredictions);
        }

      } catch (error) {
        console.error(`Error fetching or processing prediction data: `, error);
        //console.error(`Error fetching or processing prediction data: ${tickerSymbol}`, error);
      }
    };
  
   /* const handlePredictedLossClick = async () => {
      try {
        const tickers = await fetchTickerData(spmUrl); // Assuming this returns an array of TickerData objects
    
        const batchSize = 20; // Number of tickers to fetch in each batch
        const tickerBatches = [];
        for (let i = 0; i < tickers.length; i += batchSize) {
          tickerBatches.push(tickers.slice(i, i + batchSize));
        }
    
        const predictions = [];
    
        for (const batch of tickerBatches) {
          const tickerPromises = batch.map(async (tickerData: TickerData, index: number) => {
            const tickerSymbol = tickerData.toString();
    
            // Skip tickers that contain the caret symbol
            if (tickerSymbol.includes('=i')|| tickerSymbol.includes('^') || tickerSymbol.includes('(delisted)')) {
              return null; // Skip this ticker
            }
    
            const fetchUrl = `../DATA/Organized/${tickerSymbol}/${tickerSymbol}.json?timestamp=${new Date().getTime()}`;
            const response = await fetch(fetchUrl);
            const tickerDataArray = await response.json();
    
            if (!Array.isArray(tickerDataArray) || tickerDataArray.length === 0) {
              console.warn(`Ticker data is not an array or is empty for ticker: ${tickerSymbol}`);
              return null; // Skip this ticker
            }
    
            // Use the current date as the target date
            const todayDate = new Date().toISOString().split('T')[0];
            const today = getTodayClose(tickerDataArray, todayDate, tickerSymbol);
            console.log(`tickerData Array : `, tickerDataArray);
    
            if (!today) {
              console.warn(`No data found for today's date or the last 3 days for ticker: ${tickerSymbol}`);
              return null; // Skip this ticker if no data is found
            }
    
            const futureDate = getFutureDate(today.date, selectedDays, tickerDataArray);
            const futurePrediction = getPredictionByDate(tickerDataArray, futureDate);
            console.log(`Data array is: `, tickerDataArray[index].Close);
            console.log(`Today close is: ${today.close}`);
            // Ensure that today.close and futurePrediction are valid numbers
            const todayClose = isNaN(today.close) ? 0 : today.close;
            const futureClose = isNaN(futurePrediction) ? 0 : futurePrediction;
            const changeValue = futureClose - todayClose;
            const changePercentage = todayClose > 0 ? (changeValue / todayClose) * 100 : todayClose <0 ? (changeValue / todayClose) * -100 : changeValue * 100;
    
            return {
              ticker: tickerSymbol,
              todayClose: todayClose,
              futureClose: futureClose,
              changeValue: changeValue,
              changePercentage: isNaN(changePercentage) ? 0 : changePercentage,
            };
          });
    
          const tickerDataArray = await Promise.all(tickerPromises);
    
          const batchPredictions = tickerDataArray
            .filter(tickerData => tickerData !== null) // Filter out null values (skipped tickers)
            .map(tickerData => tickerData as PredictionResult); // Type assertion to PredictionResult
    
          predictions.push(...batchPredictions);
        }/*
  
        // Filter out predictions with non-positive changes and sort the rest by changeValue
      const positivePredictions = predictions
      .filter(prediction => prediction.changePercentage < 0) // Only positive changes
      .sort((a, b) => a.changePercentage - b.changePercentage); // Sort by changeValue descending
        console.log(`Predictions = ${positivePredictions}`);
        setPredictedData(positivePredictions);
      } catch (error) {
        console.error(`Error fetching or processing prediction data:`, error);
      }
    };
    */
    
    
    
  
    return (
      <div className="searchBar_container">
        {/*isOverlayVisible && premiumType == "free" && (
          <a href='https://stighoazon.com/4/7822902' className="searchBar_ad" onClick={handleOverlayClick} target="_blank" rel="noopener">
          </a>
        )*/}
        <div className="searchBar_searchType-container">
          <h3 className="searchBar_searchType-subTitles">Search By</h3>
          <ul className="searchBar_searchType-option-container">
            <li className="searchBar_searchType-optionBtn" onClick={() => handlePredictedChangeClick("Growth")}>Predicted Growth</li>
            <li className="searchBar_searchType-optionBtn" onClick={() => handlePredictedChangeClick("Drop")}>Predicted Loss</li>
          </ul>        
        </div>
        <div className="searchBar_searchType-container">
          <h3 className="searchBar_searchType-subTitles">In the Next</h3>
          <ul className="searchBar_searchType-option-container">
          <select className="searchBar_searchType-option-daySelector" value={selectedDays} onChange={handleChange}>
          {Array.from({ length: 14 }, (_, index) => index + 1).map((day) => (
            <option key={day} value={day}>
              {day > 1 ? day + " Trading Days" : "Trading Day"}
            </option>
          ))}
        </select>
          </ul>
        </div>
        <div className="searchBar_searchType-resultsContainer">
          <div className='searchBar_searchType-results_tableContainer'>
          <div className="searchBar_searchType-results_table-header">Ticker</div>
          <div className="searchBar_searchType-results_table-header">Close</div>
          <div className="searchBar_searchType-results_table-header">Predicted Close</div>
          <div className="searchBar_searchType-results_table-header">Predicted $ Change</div>
          <div className="searchBar_searchType-results_table-header">Predicted % Change</div>
          </div>
          {predictedData.map((data, index) => (
            
    <div className="searchBar_searchType-results_ticker-Container" key={index}>
      <div className="searchBar_searchType-results_tickerDetails-container" onClick={() => handleResultClick(data.ticker)}>
        <img
          className="searchBar_searchType-results_tickerDetails-Img"
          src={`../DATA/Organized/${data.ticker}/${data.ticker}.svg`}
          alt=''
          onError={(e) => {
            e.currentTarget.onerror = null; // Prevent infinite loop if fallback fails
            e.currentTarget.src = ''; // Fallback image path
            e.currentTarget.style.display = "none"; // Fallback image path
          }}
        />
        <h5 className="searchBar_searchType-results_tickerDetails-ticker">
          {data.ticker}
        </h5>
      </div>
      <div className="searchBar_searchType-results_tickerDetails-marketClose">
        {data.todayClose.toFixed(2)}
      </div>
      <div className="searchBar_searchType-results_tickerDetails-spmClose">
        {data.futureClose.toFixed(2)}
      </div>
      <div className={`searchBar_searchType-results_tickerDetails-spmChange-${data.changeValue >= 0 ? 'positive' : 'negative'}`}>
        {data.changeValue.toFixed(2)}
      </div>
      <div className={`searchBar_searchType-results_tickerDetails-spmPerChange-${data.changePercentage >= 0 ? 'positive' : 'negative'}`}>
        {data.changePercentage.toFixed(2)}%
      </div>
    </div>
  ))}
  
        </div>
      </div>
    );
  };
  
  export default SearchBar;
  