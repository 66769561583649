import React, { useState, useEffect } from 'react';



interface TickerInfo {
    Date: string;
    Open: string;
    High: string;
    Low: string;
    Close: string;
    Prediction: string;
    Accuracy: string;
    companyName?: string;
    livePrice?: string;
    priceChange?: string;
    percentageChange?: string;
    exchange?: string;
}

const HiddenDataFetcher: React.FC = () => {
  const [tickerDataStorage, settickerDataStorage] = useState<TickerInfo | null>(null);

  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const timestamp = new Date().getTime();
      try {
        const [tickerData] = await Promise.all([
          fetch(`/home/customer/www/stockpricemaximizer.com/DATA-TEST/
            Organized/NVDA/NVDA.jsont=${timestamp}`)
        ]);

        if (!tickerData.ok) throw new Error(`HTTP error! status: ${tickerData.status}`);

        const tickerJsonData = await tickerData.json();
        console.log(tickerJsonData);
        settickerDataStorage(tickerJsonData);

      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Data not available.');
      }
    };

    fetchData();
  }, []);

  if (error) return <div className="appVersion error">{error}</div>;
  if (!tickerDataStorage) return <div className="appVersion loading">Loading update information...</div>;

  return (
    <div className="appVersion">
      <p>Ticker Data: {tickerDataStorage.Close}</p>
    </div>
  );
};

export default HiddenDataFetcher;