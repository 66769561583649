import React, { useState, useEffect, useRef } from 'react';

interface TickersCarousellProps {
  handleNavigation: (path: string) => void;
  spmUrl: string;
  premiumType: string;
}

const TickersCarousell: React.FC<TickersCarousellProps> = ({ handleNavigation, spmUrl, premiumType }) => {
  const [tickers, setTickers] = useState<string[]>([]);
  const [isAdVisible, setIsAdVisible] = useState(true);
  const carouselRef = useRef<HTMLDivElement>(null);
  const carouselAdRef = useRef<HTMLAnchorElement>(null);
  const trackRef = useRef<HTMLDivElement>(null);
  const animationFrameRef = useRef<number | null>(null);
  const interactionTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const scrollSpeed = 0.5; // Adjust the speed as needed

  useEffect(() => {
    fetch(`${spmUrl}php/jsonFileLocator.php`)
      .then(response => response.json())
      .then(data => {
        setTickers(data);
      })
      .catch(error => console.error('Failed to fetch tickers', error));
  }, [spmUrl]);

  const performScroll = () => {
    if (trackRef.current && carouselRef.current) {
      const currentTransform = window.getComputedStyle(trackRef.current).transform;
      const translateX = currentTransform !== 'none' ? parseFloat(currentTransform.split(',')[4]) : 0;
      const newTransform = translateX - scrollSpeed;
      trackRef.current.style.transform = `translateX(${newTransform}px)`;

      const trackRect = trackRef.current.getBoundingClientRect();
      const carouselRect = carouselRef.current.getBoundingClientRect();

      if (trackRect.right <= 0) {
        trackRef.current.style.transform = `translateX(0px)`;
        carouselRef.current.scrollLeft = 0;
      }

      animationFrameRef.current = requestAnimationFrame(performScroll);
    }
  };

  useEffect(() => {
    const startScrolling = () => {
      if (trackRef.current && carouselRef.current) {
        trackRef.current.style.transform = `translateX(${carouselRef.current.offsetWidth}px)`;
      }
      performScroll();
    };

    startScrolling();

    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, []);

  const handleUserInteractionStart = (event: Event) => {
    if (event instanceof WheelEvent && event.deltaX === 0) {
      return; // Ignore vertical scroll events
    }
    if (animationFrameRef.current) {
      cancelAnimationFrame(animationFrameRef.current);
    }
    if (interactionTimeoutRef.current) {
      clearTimeout(interactionTimeoutRef.current);
    }
  };

  const handleUserInteractionEnd = () => {
    interactionTimeoutRef.current = setTimeout(() => {
      performScroll();
    }, 1000);
  };

  const handleWheelEvent = (event: WheelEvent) => {
    if (event.deltaX !== 0) {
      handleUserInteractionStart(event);
      handleUserInteractionEnd();
    }
  };

  const handleTickerClick = (ticker: string) => {
    handleNavigation(`/v1/${ticker}`);
  };

  const handleAdClick = () => {
    setIsAdVisible(false);
  };

  const getDuplicatedTickers = () => {
    return [...tickers, ...tickers];
  };

  // Reset ad visibility on component mount
  useEffect(() => {
    setIsAdVisible(true);
  }, []);

  useEffect(() => {
    const carousel = carouselRef.current;

    if (carousel) {
      carousel.addEventListener('mousedown', handleUserInteractionStart);
      carousel.addEventListener('touchstart', handleUserInteractionStart);
      carousel.addEventListener('scroll', handleUserInteractionStart);
      carousel.addEventListener('wheel', handleWheelEvent);
      carousel.addEventListener('mouseup', handleUserInteractionEnd);
      carousel.addEventListener('touchend', handleUserInteractionEnd);
    }

    return () => {
      if (carousel) {
        carousel.removeEventListener('mousedown', handleUserInteractionStart);
        carousel.removeEventListener('touchstart', handleUserInteractionStart);
        carousel.removeEventListener('scroll', handleUserInteractionStart);
        carousel.removeEventListener('wheel', handleWheelEvent);
        carousel.removeEventListener('mouseup', handleUserInteractionEnd);
        carousel.removeEventListener('touchend', handleUserInteractionEnd);
      }
    };
  }, []);

  return (
    <div className="tickers">
      <div className="carousel" ref={carouselRef} style={{ overflowX: 'scroll', whiteSpace: 'nowrap' }}>
        {isAdVisible && premiumType == "free" && (
          <a
            className="carousel-ad"
            ref={carouselAdRef}
            onClick={handleAdClick}
            style={{ display: 'inline-block', cursor: 'pointer' }}
            href="https://koastoothoo.com/4/7757994"
            target="_blank"
            rel="noopener"
          >
            <div className='carousel-adBody'></div>
          </a>
        )}
        <div className="carousel-track" ref={trackRef} style={{ display: 'inline-flex', willChange: 'transform' }}>
          {getDuplicatedTickers().map((ticker, index) => (
            <div
              className="tickers_Container-blur_Container"
              key={`${ticker}-${index}`}
              onClick={() => handleTickerClick(ticker)}
              style={{ flex: 'none' }}
            >
              <div className="ticker">{ticker}</div>
              <div className="tickers_Container-blur"></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TickersCarousell;
