import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useUser } from './userContext';

interface ProtectedRouteProps {
  element: React.ReactElement;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element }) => {
  const { verificationRequired, userDetails } = useUser();
  const location = useLocation();

  // Check if user is authenticated
  const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';
  const sessionId = localStorage.getItem('sessionId');
  console.log(`sessionId is: ${sessionId}`);

  // Function to check if user ID is present
  const isUserIdPresent = (): boolean => {
    return userDetails && typeof userDetails.id === 'number' && userDetails.id > 0;
  };

  // Additional condition to handle loginVerification route
  if (location.pathname === '/verification') {
    const state = location.state as { from?: { pathname: string }, email?: string };
    const referrer = state?.from?.pathname;
    const email = state?.email;

    if (!isUserIdPresent() && referrer === '/login' && email && !sessionId) {
      console.log("Access to verification granted: No ID, referrer is login, and email is present");
    } else if (isUserIdPresent() && sessionId !== "undefined") {
      console.log("Access to verification denied: ID is present");
      return <Navigate to="/dashboard" replace />;
    } else {
      console.log(`Access to verification denied: ID is present or referrer is ${referrer} or email is missing`);
      return <Navigate to="/login" replace />;
    }
  }

  if (!isAuthenticated && !verificationRequired) {
    console.log("User is not authenticated and verification is not required");
    // Redirect to login page and preserve the current location they were trying to go to
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (isAuthenticated && !isUserIdPresent() || sessionId === "undefined") {
    // Redirect authenticated users without a user ID to the login
    console.log(`Authenticated: ${isAuthenticated} or no user ID: ${!isUserIdPresent()}`);
    return <Navigate to="/login" replace />;
  }

  return element;
};

export default ProtectedRoute;
