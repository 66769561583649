import React, { useState, useEffect } from 'react';
import { Chart } from "react-google-charts";

interface AdminBoardProps {
  spmUrl: string;
  handleNavigation: (path: string) => void;
  currentLocation: { pathname: string };
  premiumType: string;
}

interface LoginActivity {
  id: number;
  user_id: number;
  year: number;
  month: number;
  day: number;
  logins: number;
  day_of_week: string;
}

const AdminBoard: React.FC<AdminBoardProps> = ({ spmUrl, handleNavigation, currentLocation, premiumType }) => {
  const [loginData, setLoginData] = useState<LoginActivity[]>([]);
  const [timeFrame, setTimeFrame] = useState<'year' | 'month' | 'day_of_week'>('month');
  const [chartData, setChartData] = useState<any[]>([]);

  useEffect(() => {
    fetchLoginData();
  }, []);

  useEffect(() => {
    processDataForChart();
  }, [loginData, timeFrame]);

  const fetchLoginData = async () => {
    try {
      const response = await fetch(`${spmUrl}php/checkVisits.php`);
      if (!response.ok) {
        throw new Error('Failed to fetch login activity data');
      }
      const data = await response.json();
      setLoginData(data.loginActivity);
    } catch (error) {
      console.error('Error fetching login activity data:', error);
    }
  };

  const processDataForChart = () => {
    if (loginData.length === 0) return;

    const aggregatedData: { [key: string]: number } = {};

    loginData.forEach((item) => {
      let key: string;
      switch (timeFrame) {
        case 'year':
          key = item.year.toString();
          break;
        case 'month':
          key = `${item.year}-${item.month.toString().padStart(2, '0')}`;
          break;
        case 'day_of_week':
          key = item.day_of_week;
          break;
      }

      if (aggregatedData[key]) {
        aggregatedData[key] += item.logins;
      } else {
        aggregatedData[key] = item.logins;
      }
    });

    const sortedData = Object.entries(aggregatedData).sort(([a], [b]) => a.localeCompare(b));

    const chartDataArray = [
      [
        { type: 'string', label: timeFrame === 'day_of_week' ? 'Day of Week' : 'Date' },
        { type: 'number', label: 'Logins' },
        { type: 'number', label: 'Trend' },
        { type: 'string', role: 'style' },
        { type: 'string', role: 'annotation' },
      ],
      ...sortedData.map(([key, value], index, array) => {
        let percentDiff = 0;
        if (index > 0) {
          const prevValue = array[index - 1][1];
          percentDiff = ((value - prevValue) / prevValue) * 100;
        }
        const trendValue = index > 0 ? value : null; // Set to null for the first item
        const color = percentDiff >= 0 ? '#01b050' : '#ff0101';
        const annotation = index > 0 ? `${percentDiff.toFixed(1)}%` : '';
        const style = `point { size: 10; shape-type: ${percentDiff >= 0 ? 'triangle' : 'triangle'};shape-rotation:${percentDiff >= 0 ? '0' : '180'}; fill-color: ${color};}`;
        return [key, value, trendValue, style, annotation];
      }),
    ];

    setChartData(chartDataArray);
  };

  return (
<div className="adminBoard_container">
      <h1 className="adminBoard_title">Admin Board</h1>
      {/*<p className="adminBoard_subtitle">Welcome, Admin User!</p>*/}
      <div className="adminBoard_filtering-container">
        <div>
          <div className="adminBoard_filtering_Options-timeFrame">
            {['year', 'month', 'day_of_week'].map((option) => (
              <div key={option} className="adminBoard_filtering_Option">
                <input
                  type="radio"
                  id={`timeframe-${option}`}
                  name="timeframe"
                  value={option}
                  checked={timeFrame === option}
                  onChange={(e) => setTimeFrame(e.target.value as typeof timeFrame)}
                  className="adminBoard_filtering_Option-input"
                />
                <label
                  htmlFor={`timeframe-${option}`}
                  className={`adminBoard_filtering-btn ${timeFrame === option ? 'adminBoard_filtering-btn--active' : ''}`}
                >
                  {option === 'day_of_week' ? 'Day of Week' : option.charAt(0).toUpperCase() + option.slice(1)}
                </label>
              </div>
            ))}
          </div>
        </div>
      </div>

      {chartData.length > 1 && (
        <div className={'adminBoard_Chart'}>
        <Chart 
          
          width={'80%'}
          height={'400px'}
          chartType="ComboChart"
          loader={<div>Loading Chart...</div>}
          data={chartData}
          options={{
            title: `Login Activity by ${timeFrame === 'day_of_week' ? 'Day of Week' : timeFrame}`,
            textStyle: { color: 'white' },
            titleTextStyle: { color: 'white' },
            backgroundColor: '#0c0c0c',
            colors: ["orange"],
            seriesType: 'bars',
            series: {
              1: { type: 'scatter', pointSize: 10 }
            },
            bar: { groupWidth: '50%', backgroundColor: "red"},
            vAxis: {
              title: 'Logins',
              minValue: 0,
              textStyle: { color: 'white' },
              titleTextStyle: { color: 'white' },
              gridlines: { color: '#ffffff33' }
            },
            hAxis: {
              title: '',
              slantedText: false,
              textStyle: { color: 'white' },
              titleTextStyle: { color: 'white' },
              gridlines: { color: '#ffffff33' }
            },
            legend: { 
              position: 'top',
              textStyle: { color: 'white' },
              titleTextStyle: { color: 'white' },
            },
            annotations: {
              textStyle: {
                fontSize: "10vw",
                color: 'white',
                auraColor: '#000',
              },
              stem: {
                color: 'transparent',
              },
              
            },
            
          }}
        />
        </div>
      )}
    </div>
  );
};

export default AdminBoard;