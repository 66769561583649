import React, { useEffect, useRef } from 'react';

interface VisitTrackerProps {
  spmUrl: string;
}

const VisitTracker: React.FC<VisitTrackerProps> = ({ spmUrl }) => {
  const hasTrackedVisit = useRef(false);

  useEffect(() => {
    if (hasTrackedVisit.current) {
      return;
    }

    const trackVisit = async () => {
      const userId = localStorage.getItem('userId');
      const sessionId = localStorage.getItem('sessionId');
      console.log('Tracking visit with userId:', userId, 'and sessionId:', sessionId);

      if (!userId || !sessionId) {
        console.error('Missing userId or sessionId');
        return;
      }

      try {
        const response = await fetch(`${spmUrl}php/track_visit.php`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ userId, sessionId, generateSessionId: false }),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          } 
          
        const data = await response.json();
        console.log('Visit tracking response:', data);
      } catch (error) {
        console.error('Error tracking visit:', error);
      }
    };

    trackVisit();
    hasTrackedVisit.current = true;
  }, [spmUrl]);

  return null;
};

export default VisitTracker;
