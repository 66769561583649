import React, { useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

interface instructions {
  title: string;
  subtitle: string;
  steps: string[];
  ctaBtns: string[];
  instructionsBtn: string;
  pricingBtn: string;
  disclaimer: string [];
  examples: string [];
  luckyPhrase: string;
}

const Instructions: React.FC<instructions> = ({ title,subtitle, steps, instructionsBtn, pricingBtn, ctaBtns, disclaimer, examples, luckyPhrase}) => {
    
    const navigate = useNavigate();
    const instructionsContainer = useRef<HTMLDivElement>(null);
    
    const openInstructions = () => {
        if(instructionsContainer.current){
            instructionsContainer.current.style.display = "block";
            /*instructionsContainer.current.style.width = '100%';
            instructionsContainer.current.style.height = '100vh';*/

        }
    }

    const closeInstructions = () => {
        if(instructionsContainer.current){
            /*instructionsContainer.current.style.width = '0';
            instructionsContainer.current.style.height = '0';*/
            instructionsContainer.current.style.display = "none";
        }
    }
    const wordSelector =(dataToSplit : string)=>{
        
        return (dataToSplit.split(' '));
    }
  return (
    <div className='instructions_component'>
        <div id="instructions-btn" onClick={openInstructions}>{instructionsBtn}</div>
        <div id="instructions-btn" onClick={() => navigate("/pricing")}>{pricingBtn}</div>
    
        <div className="instructions_container" ref={instructionsContainer}>
            <div className='instructions_subContainer'>
                <div className="instructions_subContainer-closeBtn"  onClick={closeInstructions}>X</div>
                {/*<h1 className="instructions_title">{title}</h1>*/}
                
                    <ul className="instructions_listContainer">
                        <li>{steps[0]}</li>
                        <li>{steps[1]}</li>
                        <li>{steps[2]}</li>
                        <li>{steps[3]}</li>
                        <p className='instructions_disclaimer'>{disclaimer[0]}</p>
                    </ul>
                        <span className='instructions_listContainer-separator'></span>

                    <h3>{subtitle}</h3>
                    <div className='instructions_Examples-container'>
                        <p className='instructions_Examples-exampleWord'><b>Example 1 </b></p>
                        <p className='instructions_Examples-exampleParahraph'> <br></br>{examples[0]}</p>
                        <p className='instructions_Examples-exampleWord'><b>Example 2 </b></p>
                        <p className='instructions_Examples-exampleParahraph'> <br></br>{examples[1]}</p>
                    </div>
                    <p className='instructions_luckyPhrase'>{luckyPhrase}</p>
                    <div className='instructions_cta-container'>
                        <div className='instructions_cta-freeUse-container'>
                        {/*<div className='instructions_cta-freeUse' onClick={closeInstructions}>*/}
                        <div className='instructions_cta-freeUse' onClick={() => navigate("/signUp")}>
                            {wordSelector(ctaBtns[0])[0]} {wordSelector(ctaBtns[0])[1]} 
                            <div className='instructions_cta-freeUse-adsLabel'>
                                {/*wordSelector(ctaBtns[0])[2]*/} {wordSelector(ctaBtns[0])[3]}
                            </div>
                        </div>
                        
                        </div>
                        <a className='instructions_cta-Paid' onClick={() => navigate("/signUp")}>{ctaBtns[1]}</a>
                        {/*<a className='instructions_cta-Paid' href='https://buy.stripe.com/9AQeVe6Z1bWR45WdQR'>{ctaBtns[1]}</a>
                        <a className='instructions_cta-Paid' href='https://buy.stripe.com/fZe6oIfvxd0V45WfZ0'>{ctaBtns[1]}</a> */}

                    </div>
                    <div className='instructions_Logo'></div>
            </div>
        </div>
    </div>
  );
}

export default Instructions;