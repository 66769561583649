import React, { useState, useEffect, useRef } from 'react';

interface LanguageSelectorProps {
  language: 'English' | 'Spanish' | 'Chinese' | 'Hindi' | 'Russian' | 'Arabic' | 'Korean' | 'German' | 'French' | 'Japanese';
  setLanguage: (language: 'English' | 'Spanish' | 'Chinese' | 'Hindi' | 'Russian' | 'Arabic' | 'Korean' | 'German' | 'French' | 'Japanese') => void;
  imagePaths: { [key: string]: string };
}

const LanguageSelector: React.FC<LanguageSelectorProps> = ({ language, setLanguage, imagePaths }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const languageSelectorRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (languageSelectorRef.current && !languageSelectorRef.current.contains(event.target as Node)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleFlagClick = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleLanguageSelect = (lang: LanguageSelectorProps['language']) => {
    setLanguage(lang);
    setDropdownOpen(false);
  };

  const getBackgroundPosition = (lang: LanguageSelectorProps['language']) => {
    return ['French', 'Japanese', 'Korean', 'Hindi'].includes(lang) ? 'center' : 'initial';
  };

  return (
    <div ref={languageSelectorRef} className="languageSelector">
      <div
        className="selected-flag"
        style={{
          backgroundImage: `url(${imagePaths[language]})`,
          backgroundPosition: getBackgroundPosition(language)
        }}
        onClick={handleFlagClick}
      />
      <div className={`flags-dropdown ${dropdownOpen ? 'show' : ''}`}>
        {Object.keys(imagePaths)
          .filter(lang => lang !== language)
          .map(lang => (
            <button
              key={lang}
              style={{ backgroundImage: `url(${imagePaths[lang]})` }}
              onClick={() => handleLanguageSelect(lang as LanguageSelectorProps['language'])}
            />
          ))}
      </div>
    </div>
  );
};

export default LanguageSelector;
