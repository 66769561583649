import React, { createContext, useContext, useState, useEffect, ReactNode, Dispatch, SetStateAction } from 'react';

// Export the User interface
export interface User {
  id: number;
  email: string;
  phone: string;
  premium: boolean;
  premium_type: string;
  city: string;
  country: string;
  tickers_frequency: string;
  nickname: string;
}

interface UserContextType {
  userDetails: User;
  setUserDetails: Dispatch<SetStateAction<User>>;
  fetchUserDetails: () => void;
  verificationRequired: boolean;
  setVerificationRequired: Dispatch<SetStateAction<boolean>>;
  spmUrl: string;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

const getInitialUserDetails = (): User => {
  const userId = localStorage.getItem('userId');
  const premium_type = localStorage.getItem('premiumType') || '';
  
  return {
    id: userId ? parseInt(userId) : 0,
    email: '',
    phone: '',
    premium: false,
    premium_type,
    city: '',
    country: '',
    tickers_frequency: '',
    nickname: '',
  };
};

export const UserProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [userDetails, setUserDetails] = useState<User>(getInitialUserDetails());
  const [verificationRequired, setVerificationRequired] = useState<boolean>(false);

  const spmUrl = process.env.REACT_APP_PHP_URL || 'http://localhost/';

  useEffect(() => {
    console.log('Initial verificationRequired:', verificationRequired);
  }, []);

  useEffect(() => {
    console.log('verificationRequired changed:', verificationRequired);
    console.log('premium type : ', userDetails.premium_type);
    console.log('id : ', userDetails.id);
  }, [verificationRequired]);

  const fetchUserDetails = async () => {
    console.log('verificationRequired changed:', verificationRequired);
    console.log('premium type : ', userDetails.premium_type);
    console.log('id : ', userDetails.id);
    try {
        const response = await fetch(`${spmUrl}php/requestUserInfo.php`, {
            method: 'POST',
            credentials: 'include', // Include cookies for session management
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ userId: userDetails.id }) // Include userId in the body
        });

        if (!response.ok) {
            throw new Error('Failed to fetch user details');
        }

        const data: User = await response.json();
        console.log('data is: ', data);
        console.log('prior: ', userDetails);
        setUserDetails(data);
        // Save user details to local storage
        localStorage.setItem('userId', userDetails.id.toString());
        localStorage.setItem('premiumType', data.premium_type);
        localStorage.setItem('isAuthenticated', 'true');
    } catch (error) {
        console.error('Error fetching user details:', error);
    }
};

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    const userPlan = localStorage.getItem('premiumType');
    const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';
    if (userId && userPlan && isAuthenticated) {
      fetchUserDetails();
    }
  }, []);

  return (
    <UserContext.Provider value={{ spmUrl, userDetails, setUserDetails, fetchUserDetails, verificationRequired, setVerificationRequired }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = (): UserContextType => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
