import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { User } from './userContext';

interface DashboardProps {
  titleTxt : string;
  subtitlesTxt : string [];
  userDetailsTxt : string [];
 billingDetailsTxt : string [];
  planDetailsTxt : string [];
  labelsTxt : string [];
  spmUrl: string;
  userDetails: User;
  setUserDetails: React.Dispatch<React.SetStateAction<User>>;
}

const Dashboard: React.FC<DashboardProps> = ({ titleTxt, subtitlesTxt,  userDetailsTxt,   billingDetailsTxt,  planDetailsTxt,  labelsTxt,  spmUrl, userDetails, setUserDetails }) => {
  const [showProfile, setShowProfile] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!userDetails.city || !userDetails.country) {
      fetch('https://ipapi.co/json/')
        .then(response => response.json())
        .then(data => {
          const { city, country_name } = data;
          setUserDetails(prevDetails => ({
            ...prevDetails,
            city: prevDetails.city || city,
            country: prevDetails.country || country_name
          }));
        })
        .catch(error => {
          console.error('Error fetching location:', error);
        });
    }
  }, [userDetails.city, userDetails.country, setUserDetails]);

  const handleProfileClick = async () => {
    setShowProfile(prevState => !prevState);

    const userId = localStorage.getItem('userId');

    if (!userId) {
      console.error('User ID not found in localStorage');
      return;
    }

    try {
      const response = await fetch(`${spmUrl}php/requestUserInfo.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId }),
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Failed to fetch user details');
      }

      const data = await response.json();
      if (data) {
        setUserDetails(data);

        // Save updated details to localStorage
        localStorage.setItem('premiumType', data.premium_type);
        localStorage.setItem('nickname', data.nickname);

        console.log('User details fetched successfully:', data);
      } else {
        console.error('No data received');
      }
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };

  const handleSubmit = async () => {
    try {
      const response = await fetch(`${spmUrl}php/updateUserInfo.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userDetails),
      });

      const data = await response.json();
      console.log(" UserDetails is: ", userDetails);

      // Save updated details to localStorage
      localStorage.setItem('userId', userDetails.id.toString());
      localStorage.setItem('premiumType', userDetails.premium_type);
      localStorage.setItem('nickname', userDetails.nickname);

      console.log('User data updated successfully:', data);
    } catch (error) {
      console.error('Error updating user data:', error);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUserDetails(prevDetails => ({ ...prevDetails, [name]: value }));
  };

  return (
    <div className="Dashboard_Container">
      <div className={`Dashboard_MembershipTag${
        userDetails.premium_type === 'G' ? '-God'
        : userDetails.premium_type === 'spm' ? '-Pro' 
        : userDetails.premium_type === 'spm+' ? '-Plus'
        :'-Limited'}`} 
        onClick={handleProfileClick}>{
          userDetails.premium_type === 'G' ? 'GODLY' 
        : userDetails.premium_type === 'spm' ? 'spm' 
        : userDetails.premium_type === 'spm+' ? 'SPM+' 
        : 'Limited'}
      </div>
      
      {showProfile && (
        <div className="Dashboard_profile_Container">
          <h1 className="Dashboard-title">{titleTxt}</h1>
          <form className="Dashboard_profile-Form">
            <div className="Dashboard_profile-Form-userDetails">
              <div>
                <label>{userDetailsTxt[0]}</label>
                <input 
                  className="Dashboard_profile-Form-input" 
                  name="nickname"
                  value={userDetails.nickname || ''} 
                  onChange={handleChange}
                  onBlur={handleSubmit}
                />
              </div>
              <div>
                <label>{userDetailsTxt[1]}</label>
                <input 
                  className="Dashboard_profile-Form-input" 
                  name="email"
                  value={userDetails.email || ''} 
                  onChange={handleChange}
                  onBlur={handleSubmit}
                />
              </div>
              <div>
                <label>{userDetailsTxt[2]}</label>
                <input 
                  className="Dashboard_profile-Form-input" 
                  name="phone"
                  value={userDetails.phone || ''} 
                  placeholder='+ 012-345-6789'
                  onChange={handleChange}
                  onBlur={handleSubmit}
                />
              </div>
            </div>
            <div className="Dashboard_profile-Form-locationDetails">
              <div>
                <label>{billingDetailsTxt[0]}</label>
                <input 
                  className="Dashboard_profile-Form-input" 
                  name="city"
                  value={userDetails.city || ''} 
                  placeholder='Enter your city here'
                  onChange={handleChange}
                  onBlur={handleSubmit}
                />
              </div>
              <div>
                <label>{billingDetailsTxt[1]}</label>
                <input 
                  className="Dashboard_profile-Form-input" 
                  name="country"
                  value={userDetails.country || ''} 
                  placeholder='Enter your country here'
                  onChange={handleChange}
                  onBlur={handleSubmit}
                />
              </div>
            </div>
            <div className="Dashboard_profile-Form-planDetails">
              <label>{subtitlesTxt[2]}</label>
              <a className={`Dashboard_profile-Form-pricingBtn${
                userDetails.premium_type === 'G' ? '-God' 
                : userDetails.premium_type === 'spm' ? '-Pro' 
                : userDetails.premium_type === 'spm+' ? '-Plus'
                :'-Limited'}`} href='/dashboard'>
                {userDetails.premium_type == 'G' ? "GODLY" : 'N/A'}
                
              </a>
              {
                 userDetails.premium_type !== 'G' ? 
                userDetails.premium_type === 'spm' ? <div className='Dashboard_Membership-cancel'>{planDetailsTxt[4]}</div> 
                : userDetails.premium_type === 'spm+' ? <div className='Dashboard_Membership-cancel'>{planDetailsTxt[4]}</div>
                :<div className='Dashboard_Membership-upgrade' onClick={() => navigate("/dashboard")}>{planDetailsTxt[3]}</div>
                : ""
                
              }
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
