import React from 'react';

interface PrivacyPolicy {
  title: string;
  subtitles: string[];
  textContent: string[];
}

const PrivacyPolicy: React.FC<PrivacyPolicy> = ({ title, subtitles, textContent }) => {
  const urlRegex = /https?:\/\/[^\s/$.?#].[^\s,]*/g;

  const processSegment = (segment: string) => {
    const lines = segment.split('<br>');
    return lines.map((line, index) => (
      <React.Fragment key={index}>
        {index > 0 && <br />}
        {line}
      </React.Fragment>
    ));
  };

  const createLinkText = (text: string) => {
    const parts = text.split(urlRegex);
    const links = text.match(urlRegex);

    const result: React.ReactNode[] = [];

    parts.forEach((part, index) => {
      result.push(<React.Fragment key={`text-${index}`}>{processSegment(part)}</React.Fragment>);

      if (links && index < links.length) {
        result.push(
          <a key={`link-${index}`} className="policies_components-textContent-links" href={links[index]} target="_blank" rel="noopener noreferrer">
            {links[index]}
          </a>
        );
      }
    });

    return result;
  };

  const processListItems = (content: string) => {
    const listItems = content.split('~').filter((item) => item.trim() !== '');

    return (
      <ul>
        {listItems.map((item, index) => (
          <li key={index}>{createLinkText(item.trim())}</li>
        ))}
      </ul>
    );
  };

  const processContent = (content: string) => {
    const parts = content.split(/(<ul>|<\/ul>)/g);
    return parts.map((part, index) => {
      if (part === '<ul>') {
        return null; // handled within processListItems
      } else if (part === '</ul>') {
        return null; // handled within processListItems
      } else if (part.includes('~')) {
        return processListItems(part);
      } else {
        return <React.Fragment key={`text-${index}`}>{createLinkText(part)}</React.Fragment>;
      }
    });
  };

  return (
    <div className='policies_components'>
      <h1 className='policies_components-title'>{title}</h1>
      <h3 className='policies_components-subtitle'>Index</h3>
      <ul className='policies_components-index'>
        {subtitles.map((subtitle, index) => (
          <li key={index}>
            <a className='policies_components-index-links' href={`#${index}`}>{subtitle}</a>
          </li>
        ))}
      </ul>
      <span className='policies_components-separator'></span>
      {textContent.map((content, index) => (
        <div className='policies_components-textContent-container' key={index}>
          <h3 id={`${index}`} className='policies_components-subtitle'>{subtitles[index]}</h3>
          <p className='policies_components-textContent'>{processContent(content)}</p>
        </div>
      ))}
      <span className='policies_components-separator'></span>
    </div>
  );
}

export default PrivacyPolicy;
